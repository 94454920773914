// Slider home
/*
$('.bxslider').bxSlider({
  adaptiveHeight: true,
  mode: 'fade'
});//*/

// Menu mobile
$( ".menu-icon" ).click(function() {
    $('[role="navigation"]').toggleClass( "is-open" );
});

$( ".btn-close-nav" ).click(function() {
    $('[role="navigation"]').removeClass( "is-open" );
});

$(document).ready(function() {

    //custom animation for open/close
    $.fn.slideFadeToggle = function(speed, easing, callback) {
        return this.animate({opacity: 'toggle', height: 'toggle'}, speed, easing, callback);
    };

    $('.accordion').accordion({
        // defaultOpen: 'section1',
        defaultOpen: '',
        speed: 'fast',
        animateOpen: function (elem, opts) { //replace the standard slideUp with custom function
            elem.next().stop(true, true).slideFadeToggle(opts.speed);
        },
        animateClose: function (elem, opts) { //replace the standard slideDown with custom function
            elem.next().stop(true, true).slideFadeToggle(opts.speed);
        }
    });

/*
//Carte svg ancienne version du site
    $('#francemap').vectorMap({
        map: 'france_fr',
        //hoverOpacity: 0.5,
        hoverColor: "#e94271",
        backgroundColor: null,
        color: "#fff",
        borderColor: "#e94271",
        selectedColor: "#e94271",
        enableZoom: true,
        showTooltip: true,
        onRegionClick: function(element, code, region)
        {
            var region = code;
			window.location.href="?region="+region;
        }
    });//*/
	
/*
	var region_code = getUrlParameter('region');
	if(region_code) {
		$('path#jqvmap1_'+region_code).css({fill:"#e94271"}); 
	}
	
	$('#partenaire_mois').prop('disabled', true);
	
	// month field on annuaire page
	$('#form_partenaires').click(function() {
	
		if( $("#partenaire_annee option:selected" ).val() != '') {
			$('#partenaire_mois').prop('disabled', false);
		} else {
			$('#partenaire_mois').prop('disabled', true);
		}
			
	});
	
	// bouton tout cocher
	$('.btn-cocher').click( function(e) {
		e.preventDefault();

		var checkbox = $(this).siblings('.checkbox');		
		var checkbox_input = checkbox.children('input');
		var btn_name = $(this).text() ;
		//console.log('Btn name = '+btn_name);
		
		if( btn_name == 'Tout cocher') {
			$(checkbox_input).each( function(i){
				$(this).prop('checked', true);
			});
			$(this).text('Tout décocher');
			
		} else if( btn_name == 'Check all items') {
			$(checkbox_input).each( function(i){
				$(this).prop('checked', true);
			});
			$(this).text('Uncheck all items');
			
		} else if( btn_name == 'Tout décocher') {
			$(checkbox_input).each( function(i){
				$(this).prop('checked', false);
			});
			$(this).text('Tout cocher');
			
		} else if( btn_name == 'Uncheck all items') {
			$(checkbox_input).each( function(i){
				$(this).prop('checked', false);
			});
			$(this).text('Check all items');
		}
	});
*/

	$('.btnShare a').click(function(){
		elem = $(this);
		postToFeed(elem.data('title'), elem.data('desc'), elem.prop('href'), elem.data('image'));
		return false;
	});

	//ZONE AJOUT BATB !!!!


//>Menu principal
	$('.content-volet .sous-nav > div').hide(); //on cache tous les sous menu
	//voletEncours=$('.content-volet .sous-nav > div:first-child').attr('id'); //ON prend le premier par defaut
	//$('#'+voletEncours).show(); //on affiche une thematique par defaut (cest important d'avoir un thematique par defaut pr le bon fonctionnement du script)`
	voletEncours = false;

	$('.main-menu nav li a').click(function(ev){
		var voletDemande = $(this).attr('data-action');
		console.log(voletEncours+" "+voletDemande);
		if(voletDemande == undefined){
			return true;
		}else{
			ev.preventDefault();
		}

		//Gestion de la classe active sur l'item clique
		$('.main-menu nav li a').removeClass('active');
		$(this).addClass('active');

		//Si on clique sur l'item deja ouvert actuellement
		if (voletDemande == voletEncours){
			if (isMenuOpen == false){
				//openMenuP(); //appel de la function d'ouverture | Ca sert dans le cas d'un premier appel
			}
			else{
				$(this).removeClass('active');
				closeMenuP();
				return false;
			}
		}
		else{}

		//On cache tous le sous menu en cour (avec animation)
		/*$('#'+voletEncours).stop(false, true).fadeOut(110, function() {
			//On affiche le sous menu qui nous interesse
			//console.log('FadeOut terminé');
			$('#'+voletDemande).stop(false, true).fadeIn(150, function() {
				//console.log('FadeIn terminé');
				openMenuP(); //appel de la function d'ouverture
				voletEncours = voletDemande;//On memorise le volet ouvert
			});
		});//*/

		//On cache tous le sous menu en cour
		$('.content-volet .sous-nav > div').hide(); //on cache tous les sous menu
				
		$('#'+voletDemande).show();
		openMenuP(); //appel de la function d'ouverture
		voletEncours = voletDemande;//On memorise le volet ouvert

		return false;
	});
	//w pour fermet le volet principal
	$('.menuP-close').click(function(ev){
		ev.preventDefault();
		closeMenuP();
		return false;
	});
	
	//>Dans le volet(ouvet par le menu principal)
	sousCategorieClose();

	//>Ecouteur ouverture sous catégorie (ds volet sur desktop)
	$('.sous-nav .accordionVolet > li > a').click(function(ev){
		ev.preventDefault();
		sousCategorieActions(this);
	});

	//>Sous nav mobile
	$(".cd-panel-content #menu-main-menu>li>a, .cd-panel-content #menu-main-menu-anglais0>li>a").click(function() {
		if($(this).next("ul").length){
			//L'élément existe
			$(this).parent().children("ul").slideToggle();
			$(this).parent().siblings().children("ul").slideUp();
			$(this).parent().toggleClass("current");
			$(this).parent().siblings().removeClass("current");
			return false;
		}
	});

	//>Bouton haut de page
	var duration = 500;
	var pageTop = $(window).scrollTop();
	$(window).scroll(function() {
		if ($(this).scrollTop() > 50) {
		// Si un défillement de 50 pixels ou plus.
		// Ajoute le bouton
			$('.retour-top').fadeIn();
		} else {
			// Sinon enlève le bouton
			$('.retour-top').fadeOut();
		}
	});			
	$('.retour-top').click(function(event) {
		// Un clic provoque le retour en haut animé.
		event.preventDefault();
		$('html, body').animate({scrollTop: 0}, duration);
		return false;
	});

	//Menu sticky
	/*
	$(window).scroll(function() {
        if ($(this).scrollTop() >= $(".volet-header").height()) {
            $(".toolbar,.nano").addClass("fixed");
        }
        else {
            $(".toolbar,.nano").removeClass("fixed");
        }
    });
	*/


	//var elemBreadDel = $('.breadcrumb').find('span a[href$="/actualite-agro/"]');
	//elemBreadDel.css({'background-color':'red'});


//>Ajout d'un parametre get dans les url des iframes vidéos pour pouvoir utiliser l'api
	var paramVideoInterval;
	$(document).on('opening', '.remodal', function () {
		paramVideoInterval = setInterval(function() {
								setVideoParams();
							}, 250);

		setVideoPase();//Ici pour les cas ou l'on passe d'un mobal à l'autre
	});

	function setVideoParams() {
		var elem = document.getElementById("ma_boite");

		var iframes = $(".remodal iframe:not([data-paramsapi])");
		//console.log( iframes.length );
		//console.log( $(".remodal iframe:not(data-paramsapi)") );

		if (iframes.length == 0){
			stopSetVideoParams();
			return false;
		}
		iframes.each(function() {
			
			var src = $(this).attr('src');
			var re = /\?/;
			var delimiteurParams = src.match(re);
			//console.log(delimiteurParams);
			if (delimiteurParams == null){ src = src+'?';}

			$(this).attr( 'src', src+'&enablejsapi=1' ); //Autorise de manipuler la vidéo via l'api
			$(this).attr( 'data-paramsapi', 'true' );

			//console.log( 'parcour des iframes dans remodal' );
			//console.log( src );
		});
	}

	function stopSetVideoParams() {
	  clearInterval(paramVideoInterval);
	}

//>Event quand un modal du plugin REMODAL est en train de se refermer
	$(document).on('closing', '.remodal', function (e) {

		// Reason: 'confirmation', 'cancellation'
		//console.log('Modal is closing' + (e.reason ? ', reason: ' + e.reason : ''));
		setVideoPase();
	});

//<Mettre en pause les videos youtubes
	function setVideoPase(){
		$("iframe").each(function() {
			//console.log( $(this) );
			//console.log( $(this)[0] );
			//document.getElementById(id).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
			$(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
			//$(this)[0].stopVideo();
		});
	}

});//End document ready


//Retourne les parametres d'une url
/*
function getParameters()
{
	var urlParams,
	match,
	pl = /+/g, // Regex for replacing addition symbol with a space
	search = /([^&=]+)=?([^&]*)/g,
	decode = function (s) { return decodeURIComponent(s.replace(pl, )); },
	query = window.location.search.substring(1);
	urlParams = {};
	while (match = search.exec(query))
	urlParams[decode(match[1])] = decode(match[2]);
	return urlParams;
}//*/

//>VArs generale pour le menu principal
var voletEncours=null;
var isMenuOpen = false;
function openMenuP(){
	//>Animation d'ouverture
	$('.sous-nav').animate({
	   width: 402
	}, 120);

	//$( ".main-content" ).animate({
	//  marginLeft: 400
	//}, 120);

	$(".nano").mCustomScrollbar({
		scrollButtons:{
			enable:true
		},
		callbacks:{
			onTotalScroll:function(){
				$(".mCSB_buttonUp").css({'marginTop':0});
				$(".mCSB_buttonDown").hide();
				$(".mCSB_scrollTools a + .mCSB_draggerContainer").css({'marginBottom':0});
				$(".mCustomScrollbar > div").css({'paddingBottom':0});
			},
			onTotalScrollBack:function(){
				$(".mCSB_buttonUp").css({'marginTop':-40});
				$(".mCSB_buttonDown").show();
				$(".mCSB_scrollTools a + .mCSB_draggerContainer").css({'marginBottom':40});
				$(".mCustomScrollbar > div").css({'paddingBottom':25});
			},
		}
	});

	$(".overlay-menu").fadeIn();

	isMenuOpen = true;
	$('body').addClass('menu-principal-desktop-open');

	return true;
}
function closeMenuP(){
	//>Animation d'ouverture
	$('.sous-nav').animate({
	   width: 0
	}, 100);

	//$( ".main-content" ).animate({
	//  marginLeft: 0
	//}, 100);

	$(".nano").mCustomScrollbar("destroy");

	$(".overlay-menu").fadeOut();

	isMenuOpen = false;
	$('body').removeClass('menu-principal-desktop-open');

	$('.content-volet .sous-nav > div').hide(); //on cache tous les sous menu

	return true;
}
//>Va fermer les sous categories
function sousCategorieClose(){
	$('.sous-nav .accordionVolet > li > ul').slideUp(140);
	return true;
}

//>ON devoile la sous categorie demandee
function sousCategorieActions(cat){	
	if ( $(cat).parent().find('ul').length > 0 ){
		if ( $(cat).parent().is(".ouvert")){
			$(cat).parent().removeClass("ouvert");
			$(cat).parent().find('> ul').slideUp(140);
		}
		else{
			$(cat).parent().addClass("ouvert");
			$(cat).parent().find('> ul').slideDown(140);
		}
	}
	else{}	
	return true;	
}


	$(".remontee-article div a").hover(function() {

		$(this).children("img").stop(true,true).animate({
		   opacity: 0
		}, 300);
		$(this).children(".content-infos").addClass('over');
		$(this).children(".content-infos").children("p").stop(true,true).fadeIn(300);

	}, function() {

		$(this).children("img").stop(true,true).animate({
		   opacity: 1
		}, 300);
		$(this).children(".content-infos").removeClass('over');
		$(this).children(".content-infos").children("p").stop(true,true).fadeOut(300);

	});
	
	$(".une a").hover(function() {

		$(this).children(".content-infos").addClass('over');
		$(this).children(".content-infos").children("p").stop(true,true).fadeIn(300);

	}, function() {

		$(this).children(".content-infos").removeClass('over');
		$(this).children(".content-infos").children("p").stop(true,true).fadeOut(300);

	});




function postToFeed(title, desc, url, image){
	var obj = {method: 'feed',link: url, picture: image, name: title, description: desc};
	//function callback(response){}
	FB.ui(obj, function(response){});

	//console.log(url);
	/*
		FB.ui({
		  method: 'share',
		  href: url,
		}, function(response){});
	*/
} 


function getUrlParameter(sParam)
{
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) 
    {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) 
        {
            return sParameterName[1];
        }
    }
}          
// Responsive Youtube

$('iframe').each(function(){
    $(this).wrap('<div class="container-video"></div>');

   if ( $(this).parent().parent().is( "p" ) ) {
    $(this).parent().unwrap(); //supprime le tag "p" ajouté par le wysiwyg
  } else {}
});



//Btn de partage linkedin
(function(){

    var popupCenter = function(url, title, width, height){
        var popupWidth = width || 640;
        var popupHeight = height || 320;
        var windowLeft = window.screenLeft || window.screenX;
        var windowTop = window.screenTop || window.screenY;
        var windowWidth = window.innerWidth || document.documentElement.clientWidth;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2 ;
        var popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
        var popup = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
        popup.focus();
        return true;
    };

    document.querySelector('.share_linkedin').addEventListener('click', function(e){
        e.preventDefault();
        var url = this.getAttribute('data-url');
        var shareUrl = "https://www.linkedin.com/shareArticle?url=" + encodeURIComponent(url);
        popupCenter(shareUrl, "Partager sur Linkedin");
    });

})();